// JsFromRoutes CacheKey 1ea037fedc9fb48623f6117c5160dac8
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@consumer/services/api'

export default {
  show: /* #__PURE__ */ definePathHelper('get', '/customize-gift'),
  defaultLocation: /* #__PURE__ */ definePathHelper('get', '/consumer/customizer/default_location'),
  generalPlaces: /* #__PURE__ */ definePathHelper('get', '/consumer/customizer/general_places'),
  giftlyDesigns: /* #__PURE__ */ definePathHelper('get', '/consumer/customizer/giftly_designs'),
  recommendedDesigns: /* #__PURE__ */ definePathHelper('get', '/consumer/customizer/recommended_designs'),
  recommendedItems: /* #__PURE__ */ definePathHelper('get', '/consumer/customizer/recommended_items'),
  recommendedTaglines: /* #__PURE__ */ definePathHelper('get', '/consumer/customizer/recommended_taglines'),
  searchDesigns: /* #__PURE__ */ definePathHelper('post', '/consumer/customizer/search_designs'),
  searchPlaces: /* #__PURE__ */ definePathHelper('post', '/consumer/customizer/search_places'),
  validateTagline: /* #__PURE__ */ definePathHelper('post', '/consumer/customizer/validate_tagline'),
}
